<template>
  <v-container fluid class="mb-16">
    <v-card class="mb-5">
      <h1>HISTÓRICO BODAS</h1>
      <v-divider></v-divider>
      <v-simple-table id="totalBodas"
        data-cols-width="10,10,13,10,10,15,10,10,10,10,10,10,10,15,15,10,10,10,10,10,10,10,10,10,10,15,15,15,15,15,15,16,16,16,16,16">
        <template v-slot:default>
          <thead>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="6"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);">
                GENERALES
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="10"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                ESPACIOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="5"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                VALORACIÓN
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="2"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                ENLACE
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="2"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                APERITIVO
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="5"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                DATOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="3"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                UBICACIÓN
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="6"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                PROCEDENCIA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="5"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                ORIGEN RESERVA
              </th>
            </tr>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                AÑO
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                Nº BODAS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                ADULTOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NIÑOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                TOTAL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                MEDIA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                PISCIS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                HOTEL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LALANNE
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LAUS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                MDP
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                PANTICOSA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                SOMMOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                TOZ/PAL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                CAT.ARA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                CAT.CAT
              </th>
              
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NS / NC
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NEGATIVA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NEUTRA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                POSITIVA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                BODAS.NET
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                CIVIL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                RELIG.
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                JARDÍN
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                HOTEL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-left: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                OBJ
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                % OBJ
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                % ÉXT
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                CRÍTICAS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                % &lt;100
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                MONZON
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                O.ESP.PROPIOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                CAT
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-left: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                MONZON
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                CINCA MEDIO
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                SOMONTANO
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LITERA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                OTROS ARAGON
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                OTROS FUERA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-left: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                EXPERIENCIA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                RECOMENDACIÓN
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                RRSS o PRENSA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                BODAS.NET
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                OTROS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in resumen.filter(r => r.fecha != 0)" :key="index">
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.fecha }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.bodas || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nAdultos || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nNinos || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.total || 0 }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.avAdultos }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.piscis }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.hotel }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.lalanne }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.laus }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.mdp || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.panticosa || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.sommos || 0 }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.toz + item.palacio}}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.oAragon }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.oCat }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nsnc }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.negativa }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.neutra }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.positiva }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.bodasnetVal }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.eCivil }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.eReligioso }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.jardin }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.terraza }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center" v-if="item.objetivo != null"><v-chip @click="cambiarObjetivo(item)">{{ item.objetivo }}</v-chip></td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center" v-else><v-chip @click="cambiarObjetivo(item)">0</v-chip></td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center" v-if="item.objetivo != null">{{ item.pntObjetivo.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center" v-else>100%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.exito.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.criticas }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pnt100.toFixed(0) }}</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntMonzon.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntBodegas.toFixed(0) }}%</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntCat.toFixed(0) }}%</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.monzon.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.cinca.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.somontano.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.litera.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.oHuesca.toFixed(0) }}%</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.oFuera.toFixed(0) }}%</td>
              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.experiencia.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.recomendacion.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.rrss.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.bodasnet.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.otraReserva.toFixed(0) }}%</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
  <v-divider></v-divider>
  <v-card class="mt-5">
    <h1>BODAS POR HACER</h1>
    <v-divider></v-divider>
    <v-data-table fixed-header :items="eventos.filter(e => new Date(e.diaReal) >= new Date())" :headers="eventosHeaders" class="fixed-checkbox" item-key="idAdHoc"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [15, -1],
          }" checkbox-color="secondary" style="cursor:pointer"
          @current-items="filtradosPorHacer = $event">
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="eventos" v-model="inlineFilters"></table-filters>
      </template>
      <template v-slot:item="{ item, index }">
        <tr @click="contextMenuEvento(item)">
          <td class="espaciador"></td>
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          <td style="white-space: nowrap !important;">{{ item.dia }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.year }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.horario }}</td>
          <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{
            item.confirmada }}</td>
          <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
          <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
          <td style="white-space: nowrap !important;">{{ item.salon }}</td>
          <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
          <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
          <td style="white-space: nowrap !important;">{{ item.total }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.ubicacionEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
          <td style="white-space: nowrap !important;">{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) }}-{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ).toUpperCase() }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{ item.fechaPrueba }}</td>
          <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
          <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.habitaciones }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.contrato }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.fianza }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>

  <v-card class="mt-5">
    <h1>BODAS EFECTUADAS</h1>
    <v-divider></v-divider>
    <v-data-table fixed-header :items="eventos.filter(e => new Date(e.diaReal) < new Date())" :headers="eventosHeaders4" class="fixed-checkbox" item-key="idAdHoc"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }" checkbox-color="secondary" style="cursor:pointer"
          @current-items="filtradosEfectuadas = $event">
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="eventos" v-model="inlineFilters4"></table-filters>
      </template>
      <template v-slot:item="{ item, index }">
        <tr @click="contextMenuEvento(item)">
          <td class="espaciador">
            <!-- {{ item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0] }} -->
          </td>
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          <td style="white-space: nowrap !important;">{{ item.dia }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.year }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.horario }}</td>
          <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
          <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
          <td style="white-space: nowrap !important;">{{ item.salon }}</td>
          <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
          <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
          <td style="white-space: nowrap !important;">{{ item.total }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.ubicacionEnlace }}</td>
          <td style="white-space: nowrap !important;">{{ item.aperitivo }}</td>
          <td style="white-space: nowrap !important;">{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) }}-{{ new Date(item.fechaReserva.split("/")[2] + '-' + item.fechaReserva.split("/")[1] + '-' + item.fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ).toUpperCase() }}</td>
          <td style="white-space: nowrap !important;">{{ item.breveDato }}</td>
          <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
          <td style="white-space: nowrap !important;">{{ item.feedback }}</td>
          <td style="white-space: nowrap !important;">{{ item.bodasnet }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.habitaciones }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.contrato }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.fianza }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.valoracion }}</td>
          <td style="white-space: nowrap !important;text-align: center;">{{ item.bodasnet }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.fechaPrueba }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>

  <v-card class="mt-5">
    <h1>BODAS PERDIDAS</h1>
    <v-divider></v-divider>
    <v-data-table fixed-header :items="anuladas" :headers="eventosHeaders2" class="fixed-checkbox" item-key="idAdHoc"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }" checkbox-color="secondary" style="cursor:pointer">
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="anuladas" v-model="inlineFilters2"></table-filters>
      </template>
      <template v-slot:item="{ item, index }">
        <tr @click="contextMenuEvento(item)">
          <td class="espaciador"></td>
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          <td style="white-space: nowrap !important;">{{ item.dia }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.year }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.horario }}</td>
          <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{
            item.confirmada }}</td>
          <td style="white-space: nowrap !important;">{{ item.perdida }}</td>
          <td style="white-space: nowrap !important;">{{ item.motivoPerdida }}</td>
          <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
          <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
          <td style="white-space: nowrap !important;">{{ item.salon }}</td>
          <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
          <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
          <td style="white-space: nowrap !important;">{{ item.total }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
          <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
  
  <v-card class="mt-5">
    <h1>BODAS ELIMINADAS</h1>
    <v-divider></v-divider>
    <v-data-table fixed-header :items="eliminadas" :headers="eventosHeaders3" class="fixed-checkbox" item-key="idAdHoc"
      :single-select="true" :loading="loading" :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }" checkbox-color="secondary" style="cursor:pointer">
      <template v-slot:body.prepend="{ headers }">
        <table-filters :headers="headers" :items="eliminadas" v-model="inlineFilters3"></table-filters>
      </template>
      <template v-slot:item="{ item, index }">
        <tr @click="contextMenuEvento(item)">
          <td class="espaciador"></td>
          <td style="white-space: nowrap !important;padding-left: 22px !important;padding-right: 22px !important">{{
            index+1 }}</td>
          <td style="white-space: nowrap !important;">{{ item.dia }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.year }}</td>
          <td style="white-space: nowrap !important;padding-left: 30px !important;padding-right: 30px !important">{{
            item.horario }}</td>
          <td style="white-space: nowrap !important;padding-left: 40px !important;padding-right: 40px !important">{{
            item.confirmada }}</td>
          <td style="white-space: nowrap !important;">{{ item.eliminada }}</td>
          <td style="white-space: nowrap !important;">{{ item.motivoEliminada }}</td>
          <td style="white-space: nowrap !important;">{{ item.nombrePareja }}</td>
          <td style="white-space: nowrap !important;">{{ item.espacio }}</td>
          <td style="white-space: nowrap !important;">{{ item.salon }}</td>
          <td style="white-space: nowrap !important;">{{ item.adultos }}</td>
          <td style="white-space: nowrap !important;">{{ item.ninos }}</td>
          <td style="white-space: nowrap !important;">{{ item.total }}</td>
          <td style="white-space: nowrap !important;">{{ item.tipoBoda }}</td>
          <td style="white-space: nowrap !important;">{{ item.origenReserva }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>

  <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
    <v-btn color="primary" fab @click="exportar" large class="me-4">
      <v-icon>mdi-microsoft-excel</v-icon>
    </v-btn>

    <v-spacer></v-spacer>
    <v-btn color="primary" fab v-if="$root.acceso('CREAR_EVENTO')" @click="addEvento" large>
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
  <NewAdHoc ref="confirm"></NewAdHoc>
  <SetObjetivo ref="confirmYear"></SetObjetivo>
</v-container></template>
  
<script>
import { parseDate, perColumnFilter } from "@/utils";
import TableToExcel from "@linways/table-to-excel";

export default {
  components: {
    SetObjetivo: () => import("@/components/SetObjetivo.vue"),
    NewAdHoc: () => import("@/components/NewAdHoc.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inlineFilters: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFilters2: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFilters3: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      inlineFilters4: {
        idAdHoc: this.$route.query.filter
          ? `=${this.$route.query.filter}`
          : null,
      },
      eventos: [],
      filtradosPorHacer: [],
      filtradosEfectuadas: [],
      anuladas: [],
      eliminadas: [],
      loading: false,
      resumen: [
        {
          fecha : 2015,
          bodas : 83,
          nAdultos : 11400,
          nNinos : 845,
          total : 12245,
          avAdultos : 137,
          piscis : 24,
          hotel : 27,
          lalanne : 20,
          laus : 0,
          toz : 4,
          palacio : 0,
          oAragon : 8,
          oCat : 0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 17,
          eReligioso : 66,
          jardin : 34,
          terraza : 7,
          objetivo : 90,
          pntObjetivo : 92,
          exito : 75,
          criticas : 14,
          pnt100 : 10.84,
          pntMonzon : 61,
          pntBodegas : 29,
          pntCat : 10,
          monzon : 30,
          cinca : 17,
          somontano : 24,
          litera : 7,
          oHuesca : 12,
          oFuera : 10,
          experiencia : 64,
          recomendacion : 18,
          rrss : 1,
          bodasnet : 6,
          otraReserva : 1
        },
        {
          fecha : 2016,
          bodas : 84,
          nAdultos : 11550,
          nNinos : 850,
          total : 12400,
          avAdultos : 138,
          piscis : 24,
          hotel : 20,
          lalanne : 26,
          laus : 0,
          toz : 5,
          palacio : 0,
          oAragon : 9,
          oCat : 0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 21,
          eReligioso : 63,
          jardin : 29,
          terraza : 8,
          objetivo : 90,
          pntObjetivo : 93,
          exito : 76,
          criticas : 17,
          pnt100 : 14.29,
          pntMonzon : 52,
          pntBodegas : 37,
          pntCat : 11,
          monzon : 25,
          cinca : 15,
          somontano : 32,
          litera : 10,
          oHuesca : 8,
          oFuera : 10,
          experiencia : 63,
          recomendacion : 20,
          rrss : 0,
          bodasnet : 8,
          otraReserva : 2,
        },
        {
          fecha :2017,
          bodas :76,
          nAdultos :10860,
          nNinos :800,
          total :11660,
          avAdultos :143,
          piscis :16,
          hotel :14,
          lalanne :27,
          laus :0,
          toz :6,
          palacio :0,
          oAragon :13,
          oCat :0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil :21,
          eReligioso :55,
          jardin :17,
          terraza :10,
          objetivo :90,
          pntObjetivo :84,
          exito :69,
          criticas :19,
          pnt100 : 25.00,
          pntMonzon :39,
          pntBodegas :43,
          pntCat :17,
          monzon :29,
          cinca :11,
          somontano :32,
          litera :9,
          oHuesca :3,
          oFuera :17,
          experiencia :74,
          recomendacion :17,
          rrss :0,
          bodasnet :5,
          otraReserva :1,
        },
        {
          fecha : 2018,
          bodas : 81,
          nAdultos : 12240,
          nNinos : 846,
          total : 13086,
          avAdultos : 162,
          piscis : 20,
          hotel : 16,
          lalanne : 27,
          laus : 0,
          toz : 7,
          palacio : 0,
          oAragon : 9,
          oCat : 2,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 20,
          eReligioso : 61,
          jardin : 24,
          terraza : 6,
          objetivo : 90,
          pntObjetivo : 90,
          exito : 73,
          criticas : 17,
          pnt100 : 13.58,
          pntMonzon : 44,
          pntBodegas : 42,
          pntCat : 14,
          monzon : 30,
          cinca : 10,
          somontano : 28,
          litera : 10,
          oHuesca : 11,
          oFuera : 12,
          experiencia : 63,
          recomendacion : 16,
          rrss : 0,
          bodasnet : 18,
          otraReserva : 3,
        },
        {
          fecha : 2019,
          bodas : 72,
          nAdultos : 9326,
          nNinos : 735,
          total : 10061,
          avAdultos : 140,
          piscis : 14,
          hotel : 16,
          lalanne : 19,
          laus : 5,
          toz : 4,
          palacio : 1,
          oAragon : 9,
          oCat : 4,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 27,
          eReligioso : 45,
          jardin : 17,
          terraza : 4,
          objetivo : 90,
          pntObjetivo : 80,
          exito : 65,
          criticas : 16,
          pnt100 : 36.11,
          pntMonzon : 42,
          pntBodegas : 40,
          pntCat : 18,
          monzon : 45,
          cinca : 9,
          somontano : 13,
          litera : 7,
          oHuesca : 6,
          oFuera : 19,
          experiencia : 63,
          recomendacion : 15,
          rrss : 0,
          bodasnet : 22,
          otraReserva : 0,
        },
        {
          fecha : 2020,
          bodas : 8,
          nAdultos : 784,
          nNinos : 39,
          total : 823,
          avAdultos : 103,
          piscis : 0,
          hotel : 3,
          lalanne : 3,
          laus : 2,
          toz : 0,
          palacio : 0,
          oAragon : 0,
          oCat : 0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 3,
          eReligioso : 5,
          jardin : 0,
          terraza : 0,
          objetivo : 90,
          pntObjetivo : 8,
          exito : 7,
          criticas : 0,
          pnt100 : 75.00,
          pntMonzon : 38,
          pntBodegas : 63,
          pntCat : 0,
          monzon : 25,
          cinca : 13,
          somontano : 50,
          litera : 0,
          oHuesca : 0,
          oFuera : 13,
          experiencia : 56,
          recomendacion : 18,
          rrss : 13,
          bodasnet : 12,
          otraReserva : 1,
        },
        {
          fecha : 2021,
          bodas : 62,
          nAdultos : 6045,
          nNinos : 305,
          total : 6350,
          avAdultos : 102,
          piscis : 5,
          hotel : 5,
          lalanne : 21,
          laus : 13,
          toz : 4,
          palacio : 0,
          oAragon : 11,
          oCat : 3,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 17,
          eReligioso : 45,
          jardin : 5,
          terraza : 2,
          objetivo : 70,
          pntObjetivo : 68,
          exito : 89,
          criticas : 11,
          pnt100 : 14.52,
          pntMonzon : 16,
          pntBodegas : 61,
          pntCat : 23,
          monzon : 23,
          cinca : 4,
          somontano : 29,
          litera : 6,
          oHuesca : 10,
          oFuera : 23,
          experiencia : 58,
          recomendacion : 22,
          rrss : 9,
          bodasnet : 9,
          otraReserva : 2,
        },
        {
          fecha : 2022,
          bodas : 95,
          nAdultos : 13035,
          nNinos : 799,
          total : 13834,
          avAdultos : 146,
          piscis : 12,
          hotel : 10,
          lalanne : 28,
          laus : 20,
          toz : 2,
          palacio : 0,
          oAragon : 22,
          oCat : 1,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 14,
          eReligioso : 81,
          jardin : 16,
          terraza : 6,
          objetivo : 100,
          pntObjetivo : 95,
          exito : 95,
          criticas : 19,
          pnt100 : 13.68,
          pntMonzon : 23,
          pntBodegas : 53,
          pntCat : 24,
          monzon : 23,
          cinca : 9,
          somontano : 18,
          litera : 2,
          oHuesca : 12,
          oFuera : 26,
          experiencia : 57,
          recomendacion : 20,
          rrss : 12,
          bodasnet : 4,
          otraReserva : 8,
        }

      ]
    };
  },
  computed: {
    eventosHeaders() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "TIPO ENLACE", value: "tipoEnlace", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "UBICACIÓN ENLACE", value: "ubicacionEnlace", class: "text-no-wrap sticky-header" },
        { text: "APERITIVO", value: "aperitivo", class: "text-no-wrap sticky-header" },
        { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva" },
        { text: "PB", value: "fechaPrueba", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "BREVE DATO", value: "breveDato", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "HABITACIONES", value: "habitaciones", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONTRATO", value: "contrato", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "FIANZA", value: "fianza", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    eventosHeaders2() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "MOTIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "perdida", dataType: "select" },
        { text: "EXPLICACIÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "motivoPerdida", dataType: "select" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters2[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    eventosHeaders3() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "MOTIVO", class: "text-no-wrap sticky-header text-center espaciador", value: "eliminada", dataType: "select" },
        { text: "EXPLICACIÓN", class: "text-no-wrap sticky-header text-center espaciador", value: "motivoEliminada", dataType: "select" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters3[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
    eventosHeaders4() {
      return [
        { text: "", class: "text-no-wrap sticky-header text-center espaciador", sortable: false, align: "center", },
        { text: "Nº", class: "text-no-wrap sticky-header text-center", sortable: false, align: "center", },
        { text: "DÍA", value: "dia", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "AÑO", value: "year", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "Hº", value: "horario", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        // { text: "CONF", value: "confirmada", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "NOMBRE PAREJA", value: "nombrePareja", class: "text-no-wrap sticky-header" },
        { text: "VALORACIÓN", value: "feedback", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "BODAS.NET", value: "bodasnet", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "ESPACIO", value: "espacio", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "SALÓN", value: "salon", class: "text-no-wrap sticky-header" },
        { text: "ADULTOS", value: "adultos", class: "text-no-wrap sticky-header text-center", },
        { text: "NIÑOS", value: "ninos", class: "text-no-wrap sticky-header text-center", },
        { text: "TOTAL", value: "total", class: "text-no-wrap sticky-header text-center", },
        { text: "TIPO BODA", value: "tipoBoda", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "TIPO ENLACE", value: "tipoEnlace", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "UBICACIÓN ENLACE", value: "ubicacionEnlace", class: "text-no-wrap sticky-header" },
        { text: "APERITIVO", value: "aperitivo", class: "text-no-wrap sticky-header" },
        { text: "FECHA RESERVA", class: "text-no-wrap sticky-header text-center espaciador", value: "fechaReserva" },
        { text: "BREVE DATO", value: "breveDato", class: "text-no-wrap sticky-header" },
        { text: "ORIGEN RESERVA", value: "origenReserva", dataType: "select", class: "text-no-wrap sticky-header" },
        { text: "HABITACIONES", value: "habitaciones", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "CONTRATO", value: "contrato", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "FIANZA", value: "fianza", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
        { text: "PB", value: "fechaPrueba", dataType: "select", class: "text-no-wrap sticky-header text-center", align: "center", },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso))
        )
        .map((header) => ({
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters4[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  mounted() {
    this.getEventos();
  },
  methods: {
    exportar() {
      var libroBodas = TableToExcel.tableToBook(document.getElementById("totalBodas"), {
        sheet: {
          name: "TOTAL BODAS"
        }
      })

      var tabla = document.createElement("table");
      tabla.innerHTML = '<thead><tr>' +
        '<th data-b-a-s="thick">Nº</th>' +
        '<th data-b-a-s="thick">DÍA</th>' +
        '<th data-b-a-s="thick">AÑO</th>' +
        '<th data-b-a-s="thick">Hº</th>' +
        '<th data-b-a-s="thick">CONF</th>' +
        '<th data-b-a-s="thick">NOMBRE PAREJA</th>' +
        '<th data-b-a-s="thick">VALORACIÓN</th>' +
        '<th data-b-a-s="thick">BODAS.NET</th>' +
        '<th data-b-a-s="thick">ESPACIO</th>' +
        '<th data-b-a-s="thick">SALÓN</th>' +
        '<th data-b-a-s="thick">ADULTOS</th>' +
        '<th data-b-a-s="thick">NIÑOS</th>' +
        '<th data-b-a-s="thick">TOTAL</th>' +
        '<th data-b-a-s="thick">TIPO BODA</th>' +
        '<th data-b-a-s="thick">TIPO ENLACE</th>' +
        '<th data-b-a-s="thick">UBICACIÓN ENLACE</th>' +
        '<th data-b-a-s="thick">APERITIVO</th>' +
        '<th data-b-a-s="thick">FECHA RESERVA</th>' +
        '<th data-b-a-s="thick">BREVE DATO</th>' +
        '<th data-b-a-s="thick">ORIGEN RESERVA</th>' +
        '<th data-b-a-s="thick">HABITACIONES</th>' +
        '<th data-b-a-s="thick">CONTRATO</th>' +
        '<th data-b-a-s="thick">FIANZA</th>' +
        '<th data-b-a-s="thick">PB</th>' +
        '<th data-b-a-s="thick">TLF NOVIA</th>' +
        '<th data-b-a-s="thick">EMAIL NOVIO</th>' +
        '<th data-b-a-s="thick">EMAIL NOVIA</th>' +
        '</tr></thead>';
      var tbody = document.createElement("tbody");
      for (let i = 0; i < this.filtradosEfectuadas.length; i++) {
        var tr = document.createElement("tr");
        tr.innerHTML = '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (i + 1) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].dia || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].year || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].horario || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].confirmada == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].nombrePareja || 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].feedback || 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].bodasnet == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].espacio || 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].salon || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].adultos || 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].ninos || 0) + '</td>' +
          '<td>' + this.filtradosEfectuadas[i].total + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].tipoBoda || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].tipoEnlace || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].ubicacionEnlace || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].aperitivo || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + new Date(this.filtradosEfectuadas[i].fechaReserva.split("/")[2] + '-' + this.filtradosEfectuadas[i].fechaReserva.split("/")[1] + '-' + this.filtradosEfectuadas[i].fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) + "-" + new Date(this.filtradosEfectuadas[i].fechaReserva.split("/")[2] + '-' + this.filtradosEfectuadas[i].fechaReserva.split("/")[1] + '-' + this.filtradosEfectuadas[i].fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ) + '</td>'+
          '<td>' + (this.filtradosEfectuadas[i].breveDato || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].origenReserva || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].habitaciones == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].contrato == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].fianza == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosEfectuadas[i].fechaPrueba == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].tlfNovia || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].emailNovio || '') + '</td>' +
          '<td>' + (this.filtradosEfectuadas[i].emailNovia || '') + '</td>';
        tbody.appendChild(tr)
      }
      for (let i = 0; i < this.filtradosPorHacer.length; i++) {
        var tr = document.createElement("tr");
        tr.innerHTML = '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (i + 1) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].dia || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].year || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].horario || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].confirmada == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].nombrePareja || 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].feedback || 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].bodasnet == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].espacio || 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].salon || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].adultos || 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].ninos || 0) + '</td>' +
          '<td>' + this.filtradosPorHacer[i].total + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].tipoBoda || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].tipoEnlace || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].ubicacionEnlace || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].aperitivo || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + new Date(this.filtradosPorHacer[i].fechaReserva.split("/")[2] + '-' + this.filtradosPorHacer[i].fechaReserva.split("/")[1] + '-' + this.filtradosPorHacer[i].fechaReserva.split("/")[0]).toLocaleDateString('esp', { day : 'numeric'} ) + "-" + new Date(this.filtradosPorHacer[i].fechaReserva.split("/")[2] + '-' + this.filtradosPorHacer[i].fechaReserva.split("/")[1] + '-' + this.filtradosPorHacer[i].fechaReserva.split("/")[0]).toLocaleDateString('esp', { month : 'short'} ) + '</td>'+
          '<td>' + (this.filtradosPorHacer[i].breveDato || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].origenReserva || '') + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].habitaciones == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].contrato == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].fianza == "Sí" ? 1 : 0) + '</td>' +
          '<td data-a-h="center" data-a-v="center" data-a-wrap="false">' + (this.filtradosPorHacer[i].fechaPrueba == "Sí" ? 1 : 0) + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].tlfNovia || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].emailNovio || '') + '</td>' +
          '<td>' + (this.filtradosPorHacer[i].emailNovia || '') + '</td>';
        tbody.appendChild(tr)
      }
      tabla.appendChild(tbody)
      tabla.setAttribute("data-cols-width", "5,12,9,7,7,30,15,13,17,15,10,10,10,15,15,20,19,15,15,17,15,15,15,15,15,25,25")
      TableToExcel.tableToSheet(libroBodas, tabla, { sheet: { name: "LISTADO BODAS" } });
      TableToExcel.save(libroBodas, "BODAS - EXPORTADO EL " + (new Date().toLocaleDateString('default', { weekday: 'short' }).toUpperCase() + ' - ' + (('0' + new Date().getDate()).slice(-2)) + (new Date().toLocaleString('default', { month: 'short' }).toUpperCase())) + " - " + new Date().getFullYear() + ".xlsx")
    },
    async cambiarObjetivo(year) {
      const dat = await this.$refs.confirmYear.open('Cambiar objetivo ' + year.fecha)
      if (dat) {
        try {
          await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API_URL}/objetivo/boda/${year.fecha}`,
            data: {
              items: dat,
            }
          })
          this.$root.$emit("snack", "Se han guardado los cambios correctamente");
          this.getEventos()
        } catch (err) {
          this.saving = false;
          this.$root.$emit("snack", "No se han podido guardar los cambios");
        }
      }
    },
    parseDate,
    async addEvento() {
      const dat = await this.$refs.confirm.open();
      if (dat == "boda") { this.$router.push({ name: "datosBoda" }) }
      else if (dat == "comunion") { this.$router.push({ name: "datosComunion" }) }
    },
    async getEventos() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/boda`,
      });

      this.eventos = data.map((m) => {
        return m;
      });

      const res = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/bodaanulada`,
      });

      this.anuladas = res.data.map((m) => {
        return m;
      });

      const res2 = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/bodaeliminada`,
      });

      this.eliminadas = res2.data.map((m) => {
        return m;
      });

      const resumen = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/bodatotal`,
      });

      this.resumen = [...this.resumen,...resumen.data];
      this.loading = false;
    },
    contextMenuEvento(item) {
      this.$router.push({ name: 'datosBoda', params: { idAdHoc: item.idAdHoc } })
    }
  },
};
</script>
<style>.espaciador {
  padding: 5px !important
}</style>